import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";
import './scss/style.scss';

const loading = () => <div className="animated fadeIn pt-3 text-center"><ClipLoader
  size={50}
  color={"#36D7B7"}
/></div>;

// Containers
const Layout = React.lazy(() => import('containers/Layout'));
const DashboardLayout = React.lazy(() => import('containers/Dashboard'));

// Pages
// const Login = React.lazy(() => import('./views/Pages/Login'));
// const Register = React.lazy(() => import('./views/Pages/Register'));
// const Page404 = React.lazy(() => import('./views/Pages/Page404'));
// const Page500 = React.lazy(() => import('./views/Pages/Page500'));

const Login = React.lazy(() => import('./components/Login/Login'));
const ClientLogin = React.lazy(() => import('./components/ClientLogin/ClientLogin'));
const SignUp = React.lazy(() => import('./components/SignUp/SignUp'));
const AutoSignin = React.lazy(() => import('./components/AutoSignin/AutoSignin'));

const App = (props) => {
  const { location } = props;
  if (location.pathname === "/") {
    const token = new URLSearchParams(location.search).get("_abtoken");
    const redirectUri = new URLSearchParams(location.search).get("redirectUri");
    if (token) {
      return <Redirect to={`/auto-signin?redirectUri=${redirectUri}&_abtoken=${token}`} />;
    }
  }

  let routes = (
    <Layout>
      <Switch>
        <Route path="/login" component={ClientLogin} />
        <Route path="/user/login" component={Login} />
        <Route path="/user" component={Login} />
        <Route path="/register/:mf_unique_number" component={SignUp} />
        <Route path="/auto-signin" component={AutoSignin} />
        <Route exact path="/" exact component={ClientLogin} />
        {/* <Route render={() => <h2>Not Found</h2>} /> */}
        <Redirect to="/" />
      </Switch>
    </Layout>
  );

  if (localStorage.getItem('__bk3__token')) {
    routes = (
      <Switch>
        <Route path="/" render={props => <DashboardLayout {...props} />} />
        <Route path="/user/login" component={Login} />
        <Route path="/auto-signin" component={AutoSignin} />
        {/* <Route render={() => <h2>Not Found</h2>} /> */}
        <Redirect to="/" />
      </Switch>
    );
  }

  return (
    <React.Suspense fallback={loading()}>
      <Switch>
        {routes}
      </Switch>
    </React.Suspense>
  );
}

export default App;
