import {
    SET_DEFAULT_CONFIG,
    SET_APP_CONFIG,
    SET_APP_SETTING,
    SET_CHARGE_OPTIONS,
    MY_PROFILE,
    LOAD_PAYMENT_GATEWAY
} from 'store/actions/actionTypes';
import { updateObject } from 'shared/common.util';

const setDefaultConfig = (state, action) => {
    let updated = {};
    if (action.payload.sidebarShow !== undefined) {
        updated.sidebarShow = action.payload.sidebarShow;
    }
    return updateObject(state, updated);
}

const setAppConfig = (state, action) => {
    let updated = {};
    if (action.payload.authRoutes !== undefined) {
        updated.authRoutes = action.payload.authRoutes;
        updated.microfinance_id = action.payload.microfinance_id;
    }
    if (action.payload.routes !== undefined) {
        updated.routes = action.payload.routes;
    }
    if (action.payload.mfRoutes !== undefined) {
        updated.mfRoutes = action.payload.mfRoutes;
    }
    return updateObject(state, updated);
};

const setAppSetting = (state, action) => {
    let updated = {};
    if (action.payload.generalSetting !== undefined) {
        updated.generalSetting = action.payload.generalSetting;
    }
    if (action.payload.titles !== undefined) {
        updated.titles = action.payload.titles;
    }
    if (action.payload.countries !== undefined) {
        updated.countries = action.payload.countries;
    }
    if (action.payload.currencies !== undefined) {
        updated.currencies = action.payload.currencies;
    }
    if (action.payload.funds !== undefined) {
        updated.funds = action.payload.funds;
    }
    if (action.payload.payment_types !== undefined) {
        updated.payment_types = action.payload.payment_types;
    }
    if (action.payload.professions !== undefined) {
        updated.professions = action.payload.professions;
    }
    if (action.payload.clientRelationships !== undefined) {
        updated.clientRelationships = action.payload.clientRelationships;
    }
    return updateObject(state, updated);
};

const setChargesOptions = (state, action) => {
    let updated = {};
    if (action.payload.charge_options !== undefined) {
        updated.charge_options = action.payload.charge_options;
    }
    if (action.payload.charge_types !== undefined) {
        updated.charge_types = action.payload.charge_types;
    }
    if (action.payload.savingChargeTypes !== undefined) {
        updated.savingChargeTypes = action.payload.savingChargeTypes;
    }
    if (action.payload.savingChargeOptions !== undefined) {
        updated.savingChargeOptions = action.payload.savingChargeOptions;
    }
    return updateObject(state, updated);
};

const setProfile = (state, action) => {
    return updateObject(state, action.payload);
};

const loadPaymentGateway = (state, action) => {
    const paymentGateway = state.paymentGateway ? state.paymentGateway : {};
    if (action.payload.banks !== undefined) {
        paymentGateway.banks = action.payload.banks;
    }
    const updated = {};
    updated.paymentGateway = paymentGateway;
    return updateObject(state, updated);
};

const initialState = {
    sidebarShow: 'responsive'
}

const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DEFAULT_CONFIG: return setDefaultConfig(state, action);
        case SET_APP_CONFIG: return setAppConfig(state, action);
        case SET_APP_SETTING: return setAppSetting(state, action);
        case SET_CHARGE_OPTIONS: return setChargesOptions(state, action);
        case MY_PROFILE: return setProfile(state, action);
        case LOAD_PAYMENT_GATEWAY: return loadPaymentGateway(state, action);
        default: return state;
    }
}

export default rootReducer
