import React from "react";
import moment from "moment";
import { TXT_MICROFINANCES } from "components/constants";
import XLSX from "xlsx";

moment.globalTimezone = "Africa/Lagos";

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const userTypes = (key) => {
  let userType = { 1: "Admin", 2: TXT_MICROFINANCES, 3: "User", 4: "Client" };
  if (userType[key]) {
    userType = userType[key];
  }
  return userType;
};

export const yesNo = (key) => {
  let YesNoObj = { 0: "No", 1: "Yes" };
  if (YesNoObj[key]) {
    YesNoObj = YesNoObj[key];
  }
  return YesNoObj;
};

export const status = (key) => {
  let statusConfig = { 0: "Inactive", 1: "Active" };
  if (statusConfig[key]) {
    statusConfig = statusConfig[key];
  }
  return statusConfig;
};

export const clientStatus = (key) => {
  //'pending','active','inactive','deceased','unspecified','closed'
  let statusConfig = {
    0: "Inactive",
    1: "Active",
    2: "Pending",
    3: "Deceased",
    4: "Unspecified",
    5: "Closed",
  };
  if (statusConfig[key]) {
    statusConfig = statusConfig[key];
  }
  return statusConfig;
};

export const genderOption = (key) => {
  let genderOption = { male: "Male", female: "Female" };
  if (genderOption[key]) {
    genderOption = genderOption[key];
  }
  return genderOption;
};

export const positionOption = (key) => {
  let positionOption = { left: "Left", right: "Right" };
  if (positionOption[key]) {
    positionOption = positionOption[key];
  }
  return positionOption;
};

export const maritalStatus = (key) => {
  let maritalStatus = {
    single: "Single",
    married: "Married",
    divorced: "Divorced",
    widowed: "Widowed",
  };
  if (maritalStatus[key]) {
    maritalStatus = maritalStatus[key];
  }
  return maritalStatus;
};

export const changeDate = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

export const changeDateTime = (date) => {
  return moment(date).format("YYYY-MM-DD h:mm A");
};

export const todayDate = () => {
  return moment().format("YYYY-MM-DD");
};
export const todayDateTime = () => {
  return moment().format("YYYY-MM-DDThh:mm");
};
export const futureDate = (num, type) => {
  return moment().add(num, type).format("YYYY-MM-DD");
};

export const diffDate = (startDate, endDate, type) => {
  var a = moment(startDate, "YYYY-MM-DD");
  var b = moment(endDate, "YYYY-MM-DD");
  return a.diff(b, type);
};

export const storageUtil = () => {
  return localStorage;
};

export const checkValidity = (value, rules, form = {}) => {
  let validity = {};
  validity.isValid = true;
  validity.message = "";
  if (!rules) {
    return validity;
  }

  if (value && Array.isArray(value)) {
    value = value.join(",");
  }

  if (rules.required) {
    validity.isValid =
      value !== null && value.toString().trim() !== "" && validity.isValid;
    if (rules.message) {
      validity.message = rules.message;
    } else {
      validity.message = "This field is required";
    }
  }

  if (
    validity.isValid &&
    rules.equalTo &&
    form[rules.equalTo] &&
    form[rules.equalTo].touched
  ) {
    validity.isValid = form[rules.equalTo].value === value;
    if (rules.message) {
      validity.message = rules.message;
    } else {
      validity.message = "The passwords do not match.";
    }
  }

  if (validity.isValid && value && rules.isDigits) {
    const pattern = /^\d+$/;
    validity.isValid = pattern.test(value) && validity.isValid;
    if (rules.message) {
      validity.message = rules.message;
    } else {
      validity.message = "Please enter a valid digits";
    }
  }

  if (validity.isValid && rules.isNumeric) {
    const pattern = /^\d+$/;
    validity.isValid = pattern.test(value) && validity.isValid;
    if (rules.message) {
      validity.message = rules.message;
    } else {
      validity.message = "Please enter a valid number";
    }
  }

  if (validity.isValid && value && rules.minLength) {
    validity.isValid =
      value.toString().length >= rules.minLength && validity.isValid;
    if (rules.message) {
      validity.message = rules.message;
    } else {
      validity.message =
        "Please enter at least " + rules.minLength + " digits.";
    }
  }

  if (validity.isValid && value && rules.maxLength) {
    validity.isValid =
      value.toString().length <= rules.maxLength && validity.isValid;
    if (rules.message) {
      validity.message = rules.message;
    } else {
      validity.message =
        "Please enter not more than " + rules.maxLength + " digits";
    }
  }

  if (validity.isValid && value && rules.min) {
    validity.isValid =
      parseFloat(value) >= parseFloat(rules.min) && validity.isValid;
    if (rules.message) {
      validity.message = rules.message;
    } else {
      validity.message =
        "Please enter a value greater than or equal to " + rules.min;
    }
  }

  if (validity.isValid && value && rules.max) {
    validity.isValid =
      parseFloat(value) <= parseFloat(rules.max) && validity.isValid;
    if (!validity.isValid) {
      if (rules.message) {
        validity.message = rules.message;
      } else {
        validity.message =
          "Please enter a value less than or equal to " + rules.max;
      }
    }
  }

  if (rules.isEmail) {
    const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    validity.isValid = pattern.test(value) && validity.isValid;
    if (rules.message) {
      validity.message = rules.message;
    } else {
      validity.message = "Please enter a valid email address";
    }
  }

  return validity;
};

export const accountingRule = (key) => {
  //'none','cash','accrual_periodic','accrual_upfront'
  // let arTypeObj = { 'none': 'None', 'cash': 'Cash' };
  let arTypeObj = { none: "None" };
  if (arTypeObj[key]) {
    arTypeObj = arTypeObj[key];
  }
  return arTypeObj;
};
export const accountType = (key) => {
  let accountTypeObj = {
    asset: "Asset",
    expense: "Expense",
    equity: "Equity",
    liability: "Liability",
    income: "Income",
  };
  if (accountTypeObj[key]) {
    accountTypeObj = accountTypeObj[key];
  }
  return accountTypeObj;
};

export const returnUserType = (id, type, data) => {
  var name = "";
  var record = {};

  if (id) {
    if (type == 1) {
      if (data.admin && Array.isArray(data.admin)) {
        record = data.admin.find((row) => row.id == id);
      } else {
        record = data.admin;
      }
      name = record ? record.first_name + " " + record.last_name : "";
    } else if (type == 2) {
      if (data.microfinance && Array.isArray(data.microfinance)) {
        record = data.microfinance.find((row) => row.id == id);
      } else {
        record = data.microfinance;
      }
      name = record ? record.name : "";
    } else if (type == 3) {
      if (data.user && Array.isArray(data.user)) {
        record = data.user.find((row) => row.id == id);
      } else {
        record = data.user;
      }
      name = record ? record.first_name + " " + record.last_name : "";
    } else if (type == 4) {
      if (data.client && Array.isArray(data.client)) {
        record = data.client.find((row) => row.id == id);
      } else {
        record = data.client;
      }
      name = record ? record.first_name + " " + record.last_name : "";
    }
  }

  return name;
};

export const loggedInName = (type, data) => {
  var name = "";
  if (type == 1) name = data ? data.first_name + " " + data.last_name : "";
  else if (type == 2) name = data ? data.name : "";
  else if (type == 3) name = data ? data.first_name + " " + data.last_name : "";
  return name;
};

export const formatNumber = (x) => {
  return x !== null ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : x;
};

export const showStatus = (key) => {
  let statusConfig = {
    0: <span className="badge badge-danger p-1">Inactive</span>,
    1: <span className="badge badge-success p-1">Active</span>,
  };

  return statusConfig[key];
};

export const loanReportType = (key) => {
  let loanReportType = {
    // 1: 'Loan',
    2: "Collection Sheet",
    3: "Repayments",
    4: "Expected Repayments",
    5: "Arrears",
    6: "Disbursement",
  };

  if (loanReportType[key]) {
    loanReportType = loanReportType[key];
  }

  return loanReportType;
};

export const loanReportTypeWithHint = (key) => {
  let loanReportType = {
    1: <span>Loan</span>,
    2: (
      <span>
        Collection Sheet{" "}
        <small>
          (See all repayments due on a particular day. Designed for loan
          officers to use in the field)
        </small>
      </span>
    ),
    3: (
      <span>
        Repayments{" "}
        <small>
          (This report shows the repayment made between start date and end date)
        </small>
      </span>
    ),
    4: (
      <span>
        Expected Repayments{" "}
        <small>
          (This report shows the expected repayments vs actual repayments within
          the specified time period)
        </small>
      </span>
    ),
    5: (
      <span>
        Arrears <small>(This report lists all loans in arrears)</small>
      </span>
    ),
    6: (
      <span>
        Disbursement{" "}
        <small>
          (Returns all loans disbursed within a certain timeperiod with their
          amounts and basic information)
        </small>
      </span>
    ),
  };

  if (loanReportType[key]) {
    loanReportType = loanReportType[key];
  }

  return loanReportType;
};

export const savingReportType = (key) => {
  let reportType = {
    1: "Savings Accounts",
    2: "Transactions",
    3: "Balances",
  };

  if (reportType[key]) {
    reportType = reportType[key];
  }

  return reportType;
};

export const savingReportTypeWithHint = (key) => {
  let reportType = {
    1: (
      <span>
        Savings Accounts{" "}
        <small>
          (This report shows all savings balances for savings accounts created
          in the specified period )
        </small>
      </span>
    ),
    2: (
      <span>
        Transactions{" "}
        <small>
          (This report shows individual deposits and withdrawals from savings
          within the specified time period)
        </small>
      </span>
    ),
    3: (
      <span>
        Balances{" "}
        <small>
          (This report shows all deposits and withdrawals from savings within
          the specified time period)
        </small>
      </span>
    ),
  };

  if (reportType[key]) {
    reportType = reportType[key];
  }

  return reportType;
};

export const accountReportType = (key) => {
  let reportType = {
    1: "Balance Sheet",
    2: "Trial Balance",
    3: "Income Statement",
  };

  if (reportType[key]) {
    reportType = reportType[key];
  }

  return reportType;
};

export const accountReportTypeWithHint = (key) => {
  let reportType = {
    1: <span>Balance Sheet</span>,
    2: <span>Trial Balance</span>,
    3: <span>Income Statement</span>,
  };

  if (reportType[key]) {
    reportType = reportType[key];
  }

  return reportType;
};

export const checkAmountNotNull = (amount) => {
  return amount ? amount : 0;
};

export const showDescription = (text) => {
  return <pre style={{ fontSize: "inherit" }}>{text}</pre>;
};

export const campaignStatus = (key) => {
  let statusConfig = {
    pending: "Pending",
    active: "Active",
    inactive: "Inactive",
    closed: "Closed",
    done: "Done",
  };
  if (statusConfig[key]) {
    statusConfig = statusConfig[key];
  }
  return statusConfig;
};
export const campaignType = (key) => {
  let options = { sms: "SMS", email: "Email" };
  if (options[key]) {
    options = options[key];
  }
  return options;
};
export const campaignTriggerType = (key) => {
  // let options = { 'direct': 'Direct', 'schedule': 'Schedule', 'triggered': 'Triggered' };
  let options = {
    direct: "Direct (Business Rule should be general)",
    schedule: "Schedule",
    triggered: "Triggered",
  };
  if (options[key]) {
    options = options[key];
  }
  return options;
};
export const campaignLogStatus = (key) => {
  let options = {
    pending: "Pending",
    sent: "Sent",
    delivered: "Delivered",
    failed: "Failed",
  };
  if (options[key]) {
    options = options[key];
  }
  return options;
};
export const showCampaignLogStatus = (key) => {
  let options = {
    pending: <span className="badge badge-warning m-1">Pending</span>,
    sent: <span className="badge badge-success m-1">Sent</span>,
    delivered: <span className="badge badge-success m-1">Delivered</span>,
    failed: <span className="badge badge-danger m-1">Failed</span>,
  };
  if (options[key]) {
    options = options[key];
  }
  return options;
};
export const scheduleFrequencyType = (key) => {
  let options = {
    days: "Days",
    weeks: "Weeks",
    months: "Months",
    years: "Years",
  };
  if (options[key]) {
    options = options[key];
  }
  return options;
};

export const generalReportType = (key) => {
  let reportType = {
    1: "Group Report",
    2: "Expense Report",
    3: "Charges Report",
  };

  if (reportType[key]) {
    reportType = reportType[key];
  }
  return reportType;
};
export const generalReportTypeWithHint = (key) => {
  let reportType = {
    1: "This report shows all transactions for loan, savings and union",
    2: "This report shows all expenses",
    3: "This report is generated for the charges for loan, savings",
  };

  if (reportType[key]) reportType = reportType[key];
  else if (key !== undefined) reportType = key;

  return reportType;
};

export const paymentMode = (key) => {
  let options = {
    live: "Live",
    test: "Test",
  };
  if (options[key]) options = options[key];
  else if (key !== undefined) options = key;

  return options;
};

export const toCSV = (columns, data) => {
  // columns = ["Account Number", "Name"],
  // data = [["1234", "Angela f"], ["5421", "John B"]]

  let headers = columns.map((item) => `"${item}"`).join(",");
  let body = data
    .map((row) => row.map((item) => `"${item}"`).join(","))
    .join("\r\n");

  return "data:text/csv;charset=utf-8," + headers + "\r\n" + body;
};

export const parseXLSXtoJSON = (file, cb) => {
  var name = file.name;
  const reader = new FileReader();
  reader.onload = async (evt) => {
    const bstr = evt.target.result;

    const wb = XLSX.read(bstr, { type: "binary" });
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
    const arr = [];
    const cols = data.shift();
    data.forEach((row, i) => {
      const item = {};
      cols.forEach((col, j) => {
        if (col.includes("amount") && row[j]) {
          item[col] = +row[j].trim().replace(/[^0-9.]/g, "");
          return;
        }
        if (col.includes("date") && row[j]) {
          const dateString = row[j].trim();
          item[col] =
            dateString.length > 10
              ? changeDateTime(dateString)
              : changeDate(dateString);
          return;
        }
        item[col] = row[j]?.trim() || row[j];
      });
      arr.push(item);
    });
    cb(arr);
  };
  reader.readAsBinaryString(file);
};
